import React, { Component } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Lotus from "../../assets/legendary.mp4";
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Eth from "../../assets/ethlogo.svg";

class CustomSlide extends Component {
  render() {
    const { index, ...props } = this.props;
    return (
      <div {...props}></div>
    );
  }
}

export default class Responsive extends Component {
  render() {
    var settings = {
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1900,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true
          }
        },
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true
          }
        }
      ]
    };
    return (
        <div className='ContentCreation'style={{color:'white'}} >
          <Row>
            <Col>
            <h1 style={{color:'white'}}>
              Smart Contract Development
            </h1>
            <p>
            Smart Contracts are the heart and soul of all web3 Dapps. We are experienced in working with several blockchains and smart contract standards.
            </p>
            <ul>
              <p>
                Here is what we offer:
              </p>
              <li>
                DeFi applications
              </li>
              <li>
                NFT, Token, and other Ethereum standards and contracts.
              </li>
              <li>
              Custom decentralized solutions. 
              </li>
              <li>
                Decentralized Autonomous Organizations (DAO)
              </li>
            </ul>
            </Col>
            <Col>
            <img src={Eth} width="540px" alt="eth" ></img>
            </Col>
          </Row>
      
        
        </div>
    );
  }
}
