import React from 'react';
// import SliderMain from '../components/SliderMain';
import FeatureBox from '../components/FeatureBox';
import CarouselCollection from '../components/ContentDesign';
import CarouselNew from '../components/ContentDesign';
import AuthorList from '../components/authorList';
import Catgor from '../components/Catgor';
import Footer from '../components/footer';
import SliderMainZero from "../components/SliderMainZero";
import SmartContracts from "../components/SmartContractsSection";
import Car from "../components/carSection";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import image from '../../assets/background.png'
import whiteh from '../../assets/white.png'
import white from  '../../assets/whiteh.png'
import Email from '../components/EmailCaptureForm'
import whitechurro from '../../assets/logowhite.png'

const PoopsCase= () => (
  <div style={{margin:'5em'}}>
     
      <section >

      <Container>
        <Row>
          <Col>
          <div>

        
          <h2 style={{margin:'0 12em 1em 0', color:'white'}}>Poopyheads NFT</h2>
          <h5 style={{color:'white'}}><a href='https://opensea.io/collection/poopyheadsnft'>Poopyheads on Opensea</a></h5>
<h2 style={{color:'white'}}>Summary</h2>
<p>Poopyheads was an NFT project that stood at the crossroads between innovation and meaning. It was designed to challenge the conventional boundaries of digital art and the collectibles market. By leveraging blockchain technology it introduced a unique collection of digital assets that were not only very rare and valuable but also carried a deeper significance.</p>
         <p>
         At its core, the project was an exploration into the untapped potential of the NFT space. It aimed to redefine what digital ownership and art can be, pushing beyond the  paradigms of digital scarcity and collectibility. This involved the integration of cutting-edge technologies, unique art concepts, and novel utilities for NFT holders, setting new standards for what the market can offer.
         </p>
         <p><strong>All 555 Poopyheads sold out within 10 minutes of minting.</strong> We did not anticipate that level of demand, but it gave us the confidence we needed to fully enter the web3 space.</p>
         <h2 style={{color:'white'}}>Background</h2>
         <p>We wanted to provide the market with unique collectibles that could not be found elsewhere. As web3 users we knew the appetites of the market and what it could sustain so we decided to come up with something that no one else was doing, yet could be seen as fun and approachable.</p>
         <h2 style={{color:'white'}}>Development Process</h2>
         <h3 style={{color:'white'}}>Project Planning</h3>
         <p>As a team we were highly organized in hitting deadlines we set for ourselves. We used a variety of planning apps and held regular meetings. We decided on the overall concept and then went on to decide on an overall look and feel for the collectibles. We tested a variety of technologies to make sure that we could do what we needed to accomplish</p>
         <h3 style={{color:'white'}}>Blockchain Selection</h3>
         <p>Ethereum was a no-brainer for us since we were designing a premium look and feel for the project we wanted to be on the most well known and legitimate blockchain: Ethereum. Deploying on Ethereum was more expensive but it gave the project legitimacy and much longer lifespan.</p>
         <h3 style={{color:'white'}}>Community Development</h3>
         <p>We used all our marketing knowledge to cultivate an energetic and excited base of people that would be willing to collect our NFTs. We marketed our project on Twitter and other social media channels while paying close attention to how we market on Discord. Our Discord featured fun activities and great engagement among the community and was the exclusive location where we posted the minting link so our most fanatical members would be first to mint.</p>
         <h3 style={{color:'white'}}>Front End and Blockchain Development</h3>
         <p>The website and minting website were written in React using web3 technologies like Ethers. The smart contracts are custom ERC-721 contracts. They can be used in a variety of other applications.</p>
        <h3 style={{color:'white'}}>Challenges</h3>
        <p>Our biggest hurdles for this project were combining controversial design concepts with blockchain technologies in a way that is both attractive and approachable for the consumer. We believe we achieved this as the project became something fun and lighthearted while at the same time providing a serious product in the form of an NFT that can be expanded upon in the future.</p>
        <h3 style={{color:'white'}}>Use Cases</h3>
        <p>Poopyheads are NFTs at their core. NFTs are tokenized assets and they can be used in a variety of ways. The primary way we've used them is as collectibles although we can expand upon them in the form of games and in defi applications.</p>
          <h2 style={{color:'white'}}>Lessons Learned</h2>
          <p>We learned many valuable lessons through this project. Firstly, that you should never underestimate anything in web3. People look for good quality projects they can invest in and as long as they are interesting and appealing people will care about them.</p>
          <p>Second, we learned to have fun. Web3 is about experimentation, trying new things, and building things that are cool and amazing.</p>
         <h2 style={{color:'white'}}>
            Conclusion
         </h2>
         <p>Poopyheads was a very fun project and it combined many things we liked in art, programming and web design. We really enjoyed the project and putting it out there for the world to see.</p>
          </div>
          </Col>
          
        </Row>
      </Container>


        </section>
      




<section>
    <Footer />
    </section>
  </div>
);
export default PoopsCase;