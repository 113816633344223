import React from 'react';
// import SliderMain from '../components/SliderMain';
import FeatureBox from '../components/FeatureBox';
import CarouselCollection from '../components/ContentDesign';
import CarouselNew from '../components/ContentDesign';
import AuthorList from '../components/authorList';
import Catgor from '../components/Catgor';
import Footer from '../components/footer';
import SliderMainZero from "../components/SliderMainZero";
import SmartContracts from "../components/SmartContractsSection";
import Car from "../components/carSection";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import image from '../../assets/background.png'
import whiteh from '../../assets/white.png'
import white from  '../../assets/whiteh.png'
import Email from '../components/EmailCaptureForm'
import whitechurro from '../../assets/logowhite.png'
import poops from '../../assets/case1.png'
import lf from '../../assets/lf.png'
import churro from '../../assets/logo-01.png'

const CaseStudies= () => (
  <div>
      
      <section style={{paddingTop:'10em',backgroundColor:'black'}}>

      <Container>
        <Row>
          <Col>

          <a href="/poopyheads" target="_blank" rel="noopener noreferrer">
          <img src= {poops} alt='im' style={{maxHeight:'15em',borderRadius:'10px',boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)'}}></img>
          </a>
          </Col>

          <Col>
          <a href="/lotusfarm" target="_blank" rel="noopener noreferrer">
          <img src={lf} alt='lotus' style={{maxHeight:'15em',borderRadius:'10px',padding:'2em',boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', transition: 'transform 0.2s',backgroundColor:'white'}}></img>
           </a>
          </Col>

          <Col>
          <a href="/churroswap" target="_blank" rel="noopener noreferrer">
          <img src={churro} alt='whitechurro' style={{maxHeight:'15em',borderRadius:'10px',boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)',backgroundColor:'white'}}></img>
           </a>
          </Col>
        </Row>

      </Container>


        </section>
  



    <Footer />
  </div>
);
export default CaseStudies;