import React from 'react';
import ImageGallery from "../components/ImageGallery";
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import Gallery from '../components/gallery'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";
import car from '../../assets/Sylver.mp4';
import wild from '../../assets/wild.mp4';
import legendary from '../../assets/legendary.mp4';
import awakened from '../../assets/awakening.mp4';
import sacred from '../../assets/SacredLotus.mp4';
import petals from '../../assets/lightenedpetals.mp4';
import logo from '../../assets/logo.png';
import poops from '../../assets/poopy.png';
import vidiLo from '../../assets/rekt.mp4';
import lotuslogo from '../../assets/lotuslogo.png';
import crap from '../../assets/crapmp.mp4';
import mezcal from '../../assets/originalbacanora.jpg';
import churros from '../../assets/discordChurro.png'

export default function works() {
return (
<div style={{paddingTop:'10em',backgroundColor:'black'}}>

<Container>
  <Row> 
    <Col className="d-flex flex-wrap justify-content-center">
    <div>
      <img src={churros} alt='churros' style={{height:'20em',borderRadius:'10px'}}></img>
    </div>
       
       <div style={{padding:'0.5em'}}>
        <video src={car}  controls loop style={{maxHeight:'250px', borderRadius:'15px'}}></video>
      </div>
      <div style={{padding:'0.5em'}}>
        <video src={wild}  controls loop style={{maxHeight:'250px', borderRadius:'15px'}}></video>
      </div>
      <div style={{padding:'0.5em'}}>
        <video src={legendary}  controls loop style={{maxHeight:'250px', borderRadius:'15px'}}></video>
      </div>
      <div style={{padding:'0.5em'}}>
        <video src={awakened}  controls loop style={{maxHeight:'250px', borderRadius:'15px'}}></video>
      </div>
    </Col>
  </Row>
  <Row>
    <Col className="d-flex flex-wrap justify-content-center">
   
      <div style={{padding:'0.5em'}}>
        <video src={sacred}  controls loop style={{maxHeight:'250px', borderRadius:'15px'}}></video>
      </div>
      <div style={{padding:'0.5em'}}>
        <video src={petals}  controls loop style={{maxHeight:'250px', borderRadius:'15px'}}></video>
      </div>
      <div style={{padding:'0.5em'}}>
        <video src={vidiLo}  controls loop style={{maxHeight:'250px', maxWidth:'250px', borderRadius:'15px'}}></video>
      </div>
      <div style={{padding:'0.5em'}}>
        <video src={crap}  controls loop style={{maxHeight:'250px', maxWidth:'250px', borderRadius:'15px'}}></video>
      </div>
    </Col>
  </Row>
  <Row>
    <Col className="d-flex flex-wrap justify-content-center">
      <div style={{padding:'0.5em'}}>
        <img src={logo} style={{maxHeight:'250px', borderRadius:'15px'}} alt="logo"></img>
      </div>
      <div style={{padding:'0.5em'}}>
        <img src={poops} style={{maxHeight:'250px', borderRadius:'15px'}} alt="logo"></img>
      </div>
      <div style={{padding:'0.5em'}}>
        <img src={lotuslogo} style={{maxHeight:'250px', borderRadius:'15px'}} alt='lotus'></img>
      </div>
      <div style={{padding:'0.5em'}}>
        <img src={mezcal} style={{maxHeight:'250px', borderRadius:'15px'}} alt="logo"></img>
      </div>
    </Col>
  </Row>
</Container>

 
   



  <Footer />
</div>
);

}
