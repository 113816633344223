import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import {Link} from '@reach/router'
import './../../App.css'

function BasicExample() {
  return (
    <div className='card-container'>
    <Card style={{ width: '18rem' }}>
      {/* <Card.Img variant="top" src="holder.js/100px180" /> */}

      <Card.Body>

        <Card.Title>NFT Collection</Card.Title>
        <Card.Text>

         Includes:

        </Card.Text>
        <li>
          2D Artwork
        </li>
        <li>
          Branding and Brand Strategy
        </li>
        <li>
          OpenSea integration
        </li>
        <li>
          Branded Discord Server
        </li>
        <hr size="5" width="full" color="black"></hr>
        <Card.Text>
         Available:
        </Card.Text>
      <li>
        3D Artwork
      </li>
      <li>
        NFT Staking
      </li>
      <li>
        Branded NFT website with minting option
      </li>
      <li>
       Custom NFT and token contracts
      </li>
      <br>
      </br>
      <Card.Text>
         {/* Starting At: */}
        </Card.Text>
<h2>
  {/* $2,500 USD */}
</h2>
<Link to="/contact" style={{color:'black'}}>
  <p>
  <button className='custom-button'>Contact us for more</button>  
  </p>
</Link>

      </Card.Body>

    </Card>
    </div>
  );
}

export default BasicExample;