import React from 'react';

const Success= () => (
  <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50vh', // This makes the div take the full height of the viewport
  }}>
    <div style={{textAlign:'center'}}>
 <h1 style={{color:'white'}}>Message Sent</h1>
 <h5 style={{color:'white'}}>We will be in contact soon!</h5>
</div>
  </div>
);
export default Success;