import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import {Link} from '@reach/router'

function BasicExample() {
  return (
    <div className='card-container'>
    <Card style={{ width: '18rem' }}>
      {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
      <Card.Body className='card-content'>
        <Card.Title>Custom Web3 MVP Solutions</Card.Title>
        <Card.Text>
         
         Includes:
          
        </Card.Text>
        <li>
        Smart Contracts
        </li>
        <li>
        Website
        </li>
        <li>
        Deployment
        </li>
        <li>
          Branding and Brand Strategy
        </li>
        <li>
        Branded Discord Server
        </li>
        <li>
        Business Consulting
        </li>
        <li>
        OpenSea integration
        </li>
       
        
<h2>
  {/* Starting at $7,500 USD */}
</h2>

  <Link to="/contact" style={{color:'black'}}>
 <button className='custom-button'>Contact us for custom solutions</button>
  </Link>
  


      </Card.Body>
    </Card>
    </div>
  );
}

export default BasicExample;