import React from 'react';
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import Packages2 from '../components/packageslite1' 
import Container from 'react-bootstrap/Container';
import Packages3 from '../components/packageslite2' 
import Packages4 from '../components/packageslite3' 

// import Nav from 'react-bootstrap/Nav';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Packages= () => (

  <div style={{padding:'10em'}}>
    <Container>
<Row>
  <Col >
  <Packages2 />
  </Col>
  <Col>
  <Packages3/>
  </Col>
  {/* <Col>
  <Packages4/>
  </Col> */}
</Row>
    </Container>


  <Footer />


  </div>

);
export default Packages;