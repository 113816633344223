import React, { Component } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Lotus from "../../assets/legendary.mp4";
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class CustomSlide extends Component {
  render() {
    const { index, ...props } = this.props;
    return (
      <div {...props}></div>
    );
  }
}

export default class Responsive extends Component {
  render() {
    var settings = {
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1900,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true
          }
        },
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true
          }
        }
      ]
    };
    return (
        <div className='ContentCreation'>
          <Row>
            <Col>
            <h1 style={{color:'white'}}>
              Web3 Content Creation
            </h1>
            <p>
            Well-executed creative content for your project is extremely important to your marketing. Eye catching graphics, and relevant imagery can make or break your project.
We are ready to assist you on any content creation project.
Our creative services include:
            </p>
            <ul>
              <li>
                3D Artwork
              </li>
              <li>
                2D Artwork
              </li>
              <li>
                Photography
              </li>
              <li>
                Animated Video
              </li>
            </ul>
            </Col>
            <Col>
            <video style={{borderRadius:'35px'}} src={Lotus} width="540px" alt="Video" autoPlay muted loop></video>
            </Col>
          </Row>
      
        
        </div>
    );
  }
}
