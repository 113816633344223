import React from 'react';
// import SliderMain from '../components/SliderMain';
import FeatureBox from '../components/FeatureBox';
import CarouselCollection from '../components/ContentDesign';
import CarouselNew from '../components/ContentDesign';
import AuthorList from '../components/authorList';
import Catgor from '../components/Catgor';
import Footer from '../components/footer';
import SliderMainZero from "../components/SliderMainZero";
import SmartContracts from "../components/SmartContractsSection";
import Car from "../components/carSection";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import image from '../../assets/background.png'
import whiteh from '../../assets/white.png'
import white from  '../../assets/whiteh.png'
import Email from '../components/EmailCaptureForm'
import whitechurro from '../../assets/logowhite.png'

const Churro= () => (
  <div style={{margin:'5em'}}>
     
      <section >

      <Container>
        <Row>
          <Col>
          <h2 style={{margin:'0 12em 1em 0',color:'white'}}>ChurroSwap</h2>
       <h5 style={{color:'white'}}><a href='https://www.churroswap.io'>churroswap.io</a></h5>
<h2 style={{color:'white'}}>Summary</h2>
<p>ChurroSwap is a bilingual language decentralized platform for games, NFTs, e-learning and cryptocurrency. It is a project designed to be easy to use and for onboarding new Spanish language users of web3 technologies.</p>
         <h2 style={{color:'white'}}>Background</h2>
         <p>The Latin American market in web3 is growing and we want to help provide a platform for new users of blockchain technologies. ChurroSwap has a way to swap coins, play games, earn crypto, and learn about web3.  </p>
         <h2 style={{color:'white'}}>Services Provided</h2>
       <ul>
       <li>Bespoke Smart Contract development</li>
       <li>2D artwork</li>
       <li>React site with minting and staking capabilities</li>
       <li>Branding</li>
       <li>Metaverse videogame integration via Unity</li>
       <li>Brand Strategy</li>
       </ul>
       <h2 style={{color:'white'}}>Development Process</h2>
         <h3 style={{color:'white'}}>Project Planning</h3>
         <p>We started with the smart contracts and gaming aspects for the platform first. We wanted to make sure we had a good proof of concept. Afterward we integrated the smart contracts with the front end website. Subsequently we gave the website an attractive look that is approachable, easy to navigate and pleasing to the eye. We branded ChurroSwap features additional branding using cute dessert based characters.</p>
         <h3 style={{color:'white'}}>Blockchain Selection</h3>
         <p>We chose Binance Smart Chain for this project. The fees are much lower than on Ethereum so that makes our platform much easier to use for the chosen audience.</p>
         <h3 style={{color:'white'}}>Front End and Blockchain Development</h3>
         <p>The smart contracts for this project have a few notable standouts. The main one is that we integrated our smart contracts with the Unity game engine. This effecively allows us to use blockchain technologies from the game engine.</p>
        <h3 style={{color:'white'}}>Challenges</h3>
        <p>The biggest challenge for this project was integrating blockchain technologies with game technologies. We went from designing for the blockchain to designing for the metaverse.</p>
        <h3 style={{color:'white'}}>Use Cases</h3>
        <p>The uses for this project are numerous. From gaming, to e-learning, to defi. All of these actions earn a user a cryptocurrency that can be traded for other currencies on the platform.</p>
         <h2 style={{color:'white'}}>
            Conclusion
         </h2>
         <p>ChurroSwap was fun and challenging. The platform is fun to use and has many fun features that we believe the target audience will love!</p>
          </Col>
          
        </Row>
      </Container>


        </section>
      




<section>
    <Footer />
    </section>
  </div>
);
export default Churro;