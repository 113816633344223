import React from 'react';
// import SliderMain from '../components/SliderMain';
import FeatureBox from '../components/FeatureBox';
import CarouselCollection from '../components/ContentDesign';
import CarouselNew from '../components/ContentDesign';
import AuthorList from '../components/authorList';
import Catgor from '../components/Catgor';
import Footer from '../components/footer';
import SliderMainZero from "../components/SliderMainZero";
import SmartContracts from "../components/SmartContractsSection";
import Car from "../components/carSection";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import image from '../../assets/background.png'
import whiteh from '../../assets/white.png'
import white from  '../../assets/whiteh.png'
import Email from '../components/EmailCaptureForm'
import whitechurro from '../../assets/logowhite.png'

const home= () => (
  <div>
      <section className="jumbotron breadcumb no-bg h-vh" >
         <SliderMainZero/>
      </section>
      <section style={{backgroundColor:'black',minHeight:'21em'}}>

      <Container>
        <Row>
          <Col>

          <a href="https://opensea.io/collection/poopyheadsnft" target="_blank" rel="noopener noreferrer">
          <img src= {white} alt='im' style={{maxHeight:'15em'}}></img>
          </a>

          </Col>
          <Col>
          <a href="https://lotus-farm-v-1.vercel.app/" target="_blank" rel="noopener noreferrer">
          <img src={whiteh} alt='lotus' style={{maxHeight:'15em'}}></img>
           </a>
          </Col>

          <Col>
          <a href="https://churroswap.io" target="_blank" rel="noopener noreferrer">
          <img src={whitechurro} alt='whitechurro' style={{maxHeight:'7em',marginTop:'5em'}}></img>
           </a>
          </Col>
        </Row>
      </Container>


        </section>
      <section>
        {/* <FeatureBox/> */}
      </section>

      <section className='container no-bottom'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='text-center'>
            </div>
          </div>
          <div className='col-lg-12'>

            <SmartContracts/>

          </div>
        </div>
      </section>




      <section className='container no-bottom'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='text-center'>
            </div>
          </div>
          <div className='col-lg-12'>

            <CarouselCollection/>

          </div>
        </div>
      </section>


<div>
      <section style={{backgroundColor:"black", padding:"0px", margin:"0px"}}>

      <Container >
        <Row className="justify-content-md-center">
          <Col md='auto' className='car2' >

                <Car style={{zindex:'2'}}/>


          </Col>

        </Row>
      </Container>

      </section>

      </div>

    <Footer />
  </div>
);
export default home;