import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import {Link} from '@reach/router'
import './../../App.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Blog() {
  return (
    <div >
        <h1 style={{color:'white'}}>
            What is a DAO?
        </h1>
        <h2 style={{color:'white'}}>
            In this first video, on our Youtube Channel, we talk about what the future of organizations might look like.
        </h2>
        <h5>
            August 16, 2023
        </h5>
        <iframe
  width="853"
  height="480"
  src="https://www.youtube.com/embed/Ja9KF4gYEgk"
  title="YouTube video player"
  frameBorder="0"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  allowFullScreen
></iframe>

    </div>
  );
  
}

export default Blog;