import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

function BasicExample() {
  return (
    <Card style={{ width: '18rem' }}>
      {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
      <Card.Body>
        <Card.Title>NFT Collection</Card.Title>
        <Card.Text>
         
         Includes:
          
        </Card.Text>
        <li>
          2D Artwork
        </li>
        <li>
          Branding and Brand Strategy
        </li>
        <li>
          OpenSea integration 
        </li>
        <li>
          Branded Discord Server
        </li>

      </Card.Body>
    </Card>
  );
}

export default BasicExample;