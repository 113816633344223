import React from 'react';
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import Packages2 from '../components/packageslite1' 
import Container from 'react-bootstrap/Container';
import Packages3 from '../components/packageslite2' 
import Packages4 from '../components/packageslite3' 
import  Blog from '../components/blog'
// import Nav from 'react-bootstrap/Nav';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const BlogPage= () => (

  <div style={{padding:'10em'}}>
    <Container>
<Row>
  <Col >
 <Blog>
    
 </Blog>
  </Col>

</Row>
    </Container>


  </div>

);
export default BlogPage;