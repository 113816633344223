import React from 'react';
import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";
import services from '../pages/services';
import '../../App.css';
import backgroundImage from '../../assets/blockchain1.mp4'

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;

const slidermainzero = () => (
  <div style={{
    height: '100vh',
    width: '100vw',
    padding: 0,
    margin: 0,
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  }}>
    <video
      src={backgroundImage} // Replace with your video file path
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        zIndex: 0
      }}
      autoPlay
      loop
      muted
    />
    
       <div style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)' // Adjust the opacity to your liking
    }}></div>
    <div className="container" style={{ height: '100%' }}>
      <div className="row align-items-center" style={{ height: '100%' }}>
        <div className="col-md-6 m-auto">
          <div className="spacer-single"></div>
          <Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={600}>
            <h1 className="text-center" style={{color:'white'}}>Metaverse and dApp Development Studio</h1>
          </Reveal>
          <Reveal className='onStep' keyframes={fadeInUp} delay={600} duration={600}>
            <p className="lead text-center" style={{color:'white'}}>
              We use the power of emerging technologies to create the internet of the future.
            </p>
          </Reveal>
          <div className="spacer-10"></div>
          <Reveal className='onStep' keyframes={fadeInUp} delay={800} duration={600}>
            <div className='d-flex justify-content-center'>
              {/* <span onClick={() => window.open("/services", "_self")} className="mainbuttons" style={{color:'white'}}>Explore Our Services</span>
              <span onClick={() => window.open("/works", "_self")} style={{color:'white'}} className="mainbuttons">See Our Recent Work</span> */}
            </div>
            <div className="spacer-single"></div>
            <div className="spacer-double"></div>
          </Reveal>
        </div>
      </div>
    </div>
  </div>
);

export default slidermainzero;