import React from 'react';
// import SliderMain from '../components/SliderMain';
import FeatureBox from '../components/FeatureBox';
import CarouselCollection from '../components/ContentDesign';
import CarouselNew from '../components/ContentDesign';
import AuthorList from '../components/authorList';
import Catgor from '../components/Catgor';
import Footer from '../components/footer';
import SliderMainZero from "../components/SliderMainZero";
import SmartContracts from "../components/SmartContractsSection";
import Car from "../components/carSection";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import image from '../../assets/background.png'
import whiteh from '../../assets/white.png'
import white from  '../../assets/whiteh.png'
import Email from '../components/EmailCaptureForm'
import whitechurro from '../../assets/logowhite.png'

const Lotus= () => (
    <div style={{margin:'5em'}}>
     
    <section >

    <Container>
      <Row>
        <Col>
        <h2 style={{margin:'0 12em 1em 0',color:'white'}}>Lotus Farm: Ethereum NFT Staking</h2>
<h2 style={{color:'white'}}>Summary</h2>
<h5 style={{color:'white'}}><a href=''>lotusfarm.io</a></h5>
<p>Lotus Farm was our first serious foray into defi. It utilizes custom ERC-1155, ERC-20 and ERC-721 and bespoke contracts that connect to these NFTs. In this project, NFT smart contracts communicate with each other and a main decentralized application. There are 3 tiers of NFT smart contracts and another category in which those three must be staked into. Additionally, that second category of NFT can be staked inside the decentralized application and the user can collect Ethereum staking rewards in the form of a token.</p>
       <p>Lotus Farm is a highly innovative project that combines the fun collectible aspect of NFTs with the utility of DeFi.</p>
       <p>
      This project seeks to tokenize Ethereum staking through NFTs. 
       </p>
       <h2 style={{color:'white'}}>Background</h2>
       <p>Staking Ethereum is expensive. First you need 32 Ether in order to even start staking. This project seeks to make staking easier for people by fractionalizing the staking pool. Holders of these NFTs recieve staking rewards just as they would if they were staking the full amount of Ethereum.</p>
       <h2 style={{color:'white'}}>Services Provided</h2>
       <ul>
       <li>Bespoke Smart Contract development</li>
       <li>3D and 2D artwork</li>
       <li>React site with minting and staking capabilities</li>
       <li>Consulting, community development</li>
       <li>Branding</li>
       <li>Brand Strategy</li>
       </ul>
       <h2 style={{color:'white'}}>Development Process</h2>
       <h3 style={{color:'white'}}>Project Planning</h3>
       <p>We spent much time with the client assessing their needs. We worked the client extensively and setup frequent meetings in order to make sure we were on the same page. We also frequently assessed our skills and what hurdles we would need to get over technologically. </p>
       <h3 style={{color:'white'}}>Blockchain Selection</h3>
       <p>We selected Ethereum for this project, however there are plans to expand to other blockchains.</p>
       <h3 style={{color:'white'}}>Front End and Blockchain Development</h3>
       <p>The custom Lotus Farm website was written in React with web3 technologies. We used highly customized versions of the ERC-1155 and ERC-721 standards that allowed them to "talk" to each other. The main decentralized application is a completely novel app written in Solidity. </p>
      <h3 style={{color:'white'}}>Challenges</h3>
      <p>This project had many challenges, namely that we were doing things that had not really been done before. We worked hard however to see that everything worked as intended. Our communication with our client was key and we are very grateful to him for being communicative and clear in what he wanted from us.</p>
      <h3 style={{color:'white'}}>Use Cases</h3>
      <p>Fractionalized Ethereum staking through NFTs.</p>
        <h2 style={{color:'white'}}>Lessons Learned</h2>
        <p>We believe this project showed that almost anything is possible with NFTs. We do not have any one lesson to point to as this project really pushed the boundaries of what we knew at the time and what could actually be done. Overall we are very happy to have taken the challenge head on.</p>
      
       <h2 style={{color:'white'}}>
          Conclusion
       </h2>
       <p>Lotus Farm is at the forefront of what is possible in web3. It represents the cutting edge of blockchain technology, collectibles, and decentralized finance!</p>
        </Col>
        
      </Row>
    </Container>


      </section>
    




<section>
  <Footer />
  </section>
</div>
);
export default Lotus;