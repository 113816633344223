import * as React from 'react';
import { styled } from '@mui/material/styles';
import {LinearProgress} from '@mui/material'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import { init, send } from '@emailjs/browser'; // Import the init and send functions
// import subscribe from '../../logos/subscribe.png'
// import { useNavigate } from 'react-router-dom';
import {Link} from 'react-router-dom'
import Progress from './progress'

// Initialize EmailJS with your public key
// It's better to do this once, possibly in a higher-level component or your entry file
init("Z4tV3jN-HOrbJEHlY");

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  borderRadius: theme.spacing(2),
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const CloseButton = styled(Button)(({ theme }) => ({
  '&:hover': {
    backgroundColor: 'rgb(51, 20, 15,0.7)',
  },
}));

export default function EmailCollector() {
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [comment, setComment] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [loading, setLoading] =React.useState(false) 

  React.useEffect(() => {
    setOpen(true);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  // const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    send('service_ogjgygh', 'template_65wwi2u', {
      to_name: 'Teddy',
      from_name: name,
      message:email,
      comment:comment,
      phone:phone,

    })
      .then((result) => {
        console.log('SUCCESS!', result.status, result.text);
        handleClose(); // Close the dialog on success
        window.open("/success", "_self")
        // navigate('/work');

      }, (error) => {
        console.log('FAILED...', error);
      });
  };

  const isValidEmail = email.includes('@');
  const isButtonDisabled = !email || !isValidEmail ||!name;

  return (
   
<div style={{ backgroundColor: 'black', minHeight: '100vh', color: 'white' }}>



      <form onSubmit={handleSubmit} style={{ textAlign: 'center', margin:'10em 0 0 0'}}>
      


        <div className='div-container' style={{
 display: 'flex',
 flexDirection: 'column',
 alignItems: 'center',
 justifyContent: 'center',
 textAlign: 'center',
}}>
   <h1 style={{color:'white'}}>Work with us!</h1>
        {loading && <Progress/>}

      <input
      onChange={(e) => setName(e.target.value)}
        type="text"
        placeholder="*Name (required)"
        style={{
          padding: '10px',
          borderRadius: '10px', // Adjust the radius as needed
          border: '2px solid #ccc', // Example border styling
        }}
      />
      <br />
      <input
         onChange={(e) => setEmail(e.target.value)}
        type="email"
        placeholder="*Email (required)"
        style={{
          padding: '10px',
          borderRadius: '10px', // Consistent border radius for all inputs
          border: '2px solid #ccc',
        }}
      />
      <br />
      <input
        onChange={(e) => setPhone(e.target.value)}
        type="tel"
        placeholder="Phone"
        style={{
          padding: '10px',
          borderRadius: '10px',
          border: '2px solid #ccc',
        }}
      />
      <br />
      <input
        onChange={(e) => setComment(e.target.value)}
        type="text"
        multiline
        rows={5}
        placeholder="Comments or Other"
        style={{
          padding: '20px 10px 50px 10px',
          borderRadius: '10px',
          border: '2px solid #ccc',
        }}
      />
      <br></br>
      <button className='submitbutton'  disabled={isButtonDisabled}>Submit</button>
    </div>

    {loading && <Progress/>}





{/* 




        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="*Name"
            type="text"
            fullWidth
            variant="outlined"
    
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderWidth: '2px', // Adjust the thickness as needed
              },
              '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderWidth: '2px', // Ensure the border width stays the same on hover
              },
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderWidth: '2px', // Ensure the border width stays the same when focused
                borderColor: 'primary.main', // Optional: change the border color when focused
              },

                color: 'rgb(51, 20, 15)',
                fontWeight: 'bold',
                '&:hover': {
                  backgroundColor: 'rgb(51, 20, 15,.25)',
          color: 'hoverTextColor',
        }

            }}
          />
          <TextField
            margin="dense"
            id="email"
            label="*Email"
            type="email"
            fullWidth
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderWidth: '2px', // Adjust the thickness as needed
              },
              '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderWidth: '2px', // Ensure the border width stays the same on hover
              },
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderWidth: '2px', // Ensure the border width stays the same when focused
                borderColor: 'primary.main', // Optional: change the border color when focused
              },

                color: 'rgb(51, 20, 15)',
                fontWeight: 'bold',
                '&:hover': {
                  backgroundColor: 'rgb(51, 20, 15,.25)',
          color: 'hoverTextColor',
        }

            }}

          />
            <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Phone"
            type="text"
        
            fullWidth
            variant="outlined"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderWidth: '2px', // Adjust the thickness as needed
              },
              '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderWidth: '2px', // Ensure the border width stays the same on hover
              },
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderWidth: '2px', // Ensure the border width stays the same when focused
                borderColor: 'primary.main', // Optional: change the border color when focused
              },

                color: 'rgb(51, 20, 15)',
                fontWeight: 'bold',
                '&:hover': {
                  backgroundColor: 'rgb(51, 20, 15,.25)',
          color: 'hoverTextColor',
        }

            }}
          />
           <TextField
            autoFocus
            margin="dense"
            id="comment"
            label="Message, Request for Quote, Other"
            type="text"
            multiline
            rows={2}
            fullWidth
            variant="outlined"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderWidth: '2px', // Adjust the thickness as needed
              },
              '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderWidth: '2px', // Ensure the border width stays the same on hover
              },
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderWidth: '2px', // Ensure the border width stays the same when focused
                borderColor: 'primary.main', // Optional: change the border color when focused
              },

                color: 'rgb(51, 20, 15)',
                fontWeight: 'bold',
                '&:hover': {
                  backgroundColor: 'rgb(51, 20, 15,.25)',
          color: 'hoverTextColor',
        }

            }}
          />
           
        </DialogContent>
        <DialogActions >
          <CloseButton onClick={handleClose} color="primary"sx={{
            color: 'rgb(51, 20, 15)',
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: 'rgb(51, 20, 15,.25)',
      color:'hoverTextColor'

    }
  }}>
            Cancel
          </CloseButton>
          <Button
           disabled={isButtonDisabled}
          type="submit" sx={{
            color: 'rgb(51, 20, 15)',
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: 'rgb(51, 20, 15,.25)',
      color: 'hoverTextColor',

    }
  }}>
            Submit
          </Button>


        </DialogActions>
        {loading && <Progress/>} */}
      </form>
      </div>
  );
}