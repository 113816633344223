import React, { useEffect, useState } from "react";
import Breakpoint, { BreakpointProvider, setDefaultBreakpoints } from "react-socks";
import { Link } from '@reach/router';
import useOnclickOutside from "react-cool-onclickoutside";


setDefaultBreakpoints([
  { xs: 0 },
  { l: 1199 },
  { xl: 1200 }
]);

const NavLink = props => (
  <Link 
    {...props}
    getProps={({ isCurrent }) => {
      return {
        className: isCurrent ? 'active' : 'non-active',
      };
    }}
  />
);

const Header = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [openMenu1, setOpenMenu1] = useState(false);
  const [openMenu2, setOpenMenu2] = useState(false);
  const [openMenu3, setOpenMenu3] = useState(false);
  const [showmenu, btn_icon] = useState(false);

  const handleBtnClick = () => setOpenMenu(!openMenu);
  const handleBtnClick1 = () => setOpenMenu1(!openMenu1);
  const handleBtnClick2 = () => setOpenMenu2(!openMenu2);
  const handleBtnClick3 = () => setOpenMenu3(!openMenu3);
  const closeMenu = () => setOpenMenu(false);
  const closeMenu1 = () => setOpenMenu1(false);
  const closeMenu2 = () => setOpenMenu2(false);
  const closeMenu3 = () => setOpenMenu3(false);

  const ref = useOnclickOutside(() => closeMenu());
  const ref1 = useOnclickOutside(() => closeMenu1());
  const ref2 = useOnclickOutside(() => closeMenu2());
  const ref3 = useOnclickOutside(() => closeMenu3());

  useEffect(() => {
    const header = document.getElementById("myHeader");
    const totop = document.getElementById("scroll-to-top");
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
      btn_icon(false);
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
        totop.classList.add("show");
      } else {
        header.classList.remove("sticky");
        totop.classList.remove("show");
      }
      if (window.pageYOffset > sticky) {
        closeMenu();
      }
    });
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, []);

  return (
    <header id="myHeader" className='navbar black'>
      <div className='container' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ transform: 'scale(1)', transformOrigin: 'top left', display: 'flex', alignItems: 'center' }}>
          <NavLink to="/" className='navbar-title navbar-item'>
            <img
              src="./img/ciegalogo-01.svg"
              className="img-fluid d-block"
              alt="#"
              style={{ height: '2em' }}
            />
          </NavLink>
        </div>

        <div className="row" style={{ paddingLeft: '5em', paddingRight: '2em' }}>
          <div className="col-sm">
            <h3 onClick={() => window.open("/case-studies", "_self")} className='navs'>Case Studies</h3>
          </div>
          <div className="col-sm">
            <h3 onClick={() => window.open("/works", "_self")} className="navs">Recent Work</h3>
          </div>
          <div className="col-sm">
            <h3 onClick={() => window.open("/contact", "_self")} className="navs1" >Contact</h3>
          </div>
          <div className="col-sm">
            <h3 onClick={() => window.open("/blog", "_self")} className="navs">Blog</h3>
          </div>
        </div>

        {/* <div className='mainside'>
          <NavLink to="/wallet" className="btn-main">Connect Wallet</NavLink>
        </div> */}
        <button className="nav-icon" onClick={() => btn_icon(!showmenu)}>
          <div className="menu-line white"></div>
          <div className="menu-line1 white"></div>
          <div className="menu-line2 white"></div>
        </button>
      </div>
    </header>
  );
}

export default Header;